import { configuration } from '@/config'
import { TopBarProvider } from '@contexts/TopBarContext'
import useAuth from '@hooks/useAuth'
import useSocket from '@hooks/useSocket'
import AnomalySnackbarContent from '@layouts/SmartLayout/AnomalySnackbarContent'
import {
  // Divider,
  makeStyles
} from '@material-ui/core'
import api from '@micmnt/apis'
import log from '@pelv/frontlog'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import { memo, useCallback, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router'
import NavBar from './NavBar'
import TopBar from './TopBar'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    width: '100%',
    overflow: 'hidden'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
    // paddingTop: 30
    // [theme.breakpoints.down('sm')]: {
    //   paddingTop: '50px'
    // }
  }
}))

const DashboardLayout = ({ children }) => {
  const classes = useStyles()
  // const [isMobileNavOpen, setMobileNavOpen] = useState(false)
  // const [openMenu, setOpenMenu] = useState(false)
  const history = useHistory()
  const { user, currentProject } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const { lastMessage } = useSocket(
    configuration.webSocket,
    user?.uuid || null
  )

  // funzione che fa apparire la notifica per nuova anomalia
  const anomalyNotification = useCallback(
    async ({
      text,
      variant = 'warning',
      plantId = null,
      anomalyId = null,
      severity = null,
      description = null,
      relatedResources = null
    }) => {
      // opzioni per lo snackbar
      const options = {
        variant,
        autoHideDuration: 30000,
        content: (key, message) => (
          <AnomalySnackbarContent
            id={key}
            message={message}
            plantId={plantId}
            anomalyId={anomalyId}
            history={history}
            variant={variant}
            severity={severity}
            description={description}
            relatedResources={relatedResources}
          />
        )
      }

      enqueueSnackbar(text, options)
    },
    [enqueueSnackbar, history]
  )

  // ad ogni nuovo messaggio faccio i miei controlli
  useEffect(() => {
    const shouldISendANotification = async () => {
      if (lastMessage && lastMessage?.data) {
        const thisEvent = JSON.parse(lastMessage?.data ?? '{}')
        log({ text: 'event => ', variable: thisEvent, tag: 'main' })
        if (
          thisEvent.type === 'venera.anomaly.created' ||
					thisEvent.type === 'venera.anomaly.closed'
        ) {
          const newAnomaly = thisEvent.data ?? {}
          // mi prendo i dati relativi alle risorse collegate

          const { data: relatedResources, error: relatedResourcesError } =
						await api.get({
						  savedUrl: 'anomaliesList',
						  path: `/${newAnomaly.uuid}/resources`
						})

          if (relatedResourcesError) {
            console.error(
              'Errore mentre prendo le risorse relative alla nuova anomalia'
            )
            return
          }

          const { data: plantResource, error: plantResourceError } =
						await api.get({
						  savedUrl: 'plantsList',
						  path: `/${newAnomaly.plantId}`,
						  params: {
						    fields: 'name'
						  }
						})

          if (plantResourceError) {
            console.error(
              'Errore mentre prendo le risorse relative alla nuova anomalia'
            )
            return
          }

          const plantName = plantResource.name || null
          // mi preparo i dati per la notifica
          // const prefix = thisEvent.type === 'venera.anomaly.closed' ? 'Chiusa' : thisEvent.type === 'venera.anomaly.created' ? 'Nuova' : 'Nuova'
          const name =
						newAnomaly.metadata && newAnomaly.metadata.message
						  ? newAnomaly.metadata.message
						  : newAnomaly.configuration
						    ? newAnomaly.configuration.name
						    : 'del ' + newAnomaly.updatedAt
          const category = newAnomaly.configuration
            ? newAnomaly.configuration.category
            : ''
          let thisVariant =
						thisEvent.type === 'venera.anomaly.closed'
						  ? 'success'
						  : thisEvent.type === 'venera.anomaly.created'
						    ? 'warning'
						    : 'warning'
          // controllo se sta a severity 5
          if (
            thisEvent.type === 'venera.anomaly.created' &&
						newAnomaly.severity[newAnomaly.severity.length - 1].level === 5
          ) {
            thisVariant = 'error'
          }
          // mando la notifica
          anomalyNotification({
            text: `${plantName || 'Impianto'}: anomalia ${category || 'NOCATEGORIA'} - ${name}`,
            variant: thisVariant,
            plantId: newAnomaly.plantId || '',
            anomalyId: newAnomaly.uuid || '',
            severity:
							newAnomaly.severity[newAnomaly.severity.length - 1].level || null,
            description: newAnomaly.configuration.description || null,
            relatedResources:
							relatedResources && relatedResources.length > 0
							  ? relatedResources
							  : null
          })
        }
      }
    }

    shouldISendANotification()
    // return () => {}
  }, [lastMessage, anomalyNotification])

  const isGeDashboard = configuration.geProject === currentProject?.uuid

  return (
    <div className={classes.root}>
      <Helmet>
        {isGeDashboard
          ? (
            <link rel='icon' type='image/png' sizes='any' href='/static/ge.png' />
            )
          : (
            <link
              rel='icon'
              type='image/svg+xml'
              sizes='any'
              href='/favicon.svg'
            />
            )}
      </Helmet>
      <TopBarProvider>
        <NavBar />
        <TopBar />
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>{children}</div>
          </div>
        </div>
      </TopBarProvider>
    </div>
  )
}

DashboardLayout.propTypes = {
  children: PropTypes.node
}

export default memo(DashboardLayout)
