import LoadingCard from '@/components/LoadingCard'
import LinearRegressionChart from '@/components/charts/LinearRegressionChart'
import StringAnalysisChart from '@/components/charts/StringAnalysisChart'
import TrendOverTimeGraph from '@/components/charts/TrendOverTimeGraph'
import useAuth from '@/hooks/useAuth'
import { useClientRect } from '@/hooks/useClientRect'
import { useSelector } from '@/store'
import { getDatesFromPeriod } from '@/views/overview/AnalyticsView/pv/utils'
import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home'
import api from '@micmnt/apis'
import log from '@pelv/frontlog'
import clsx from 'clsx'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import { ChevronLeft, ChevronRight, Filter as FilterIcon } from 'react-feather'
import { v4 as uuidv4 } from 'uuid'
import {
  addStringAnalysisToDeviation,
  decodeLinearRegressionGraphFromApi,
  decodeStringAnalysisFromApi,
  decodeTrendGraph,
  getAggregationTypeFromPeriod,
  normalizeElementsFilter
} from '../utils'
import PeriodModal from './PeriodModal'
import SidebarFilters from './SidebarFilters'
import {
  baseValues,
  formatPeriod,
  getDashboardTypes
} from './SidebarFilters/FiltersContent/utils'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  loading: {
    width: '100%'
  },
  section: {
    padding: theme.spacing(1, 2),
    width: '100%',
    height: '100%'
  },
  sectionTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  },
  topMargin: {
    marginTop: theme.spacing(2) - 4
  },
  smallViewport: {
    overflowY: 'auto',
    height: '100%'
  },
  filterTitle: {
    color: theme.palette.common.white
  },
  chip: {
    backgroundColor: theme.palette.secondary.main,
    margin: theme.spacing(1, 2, 1, 0)
  },
  glassBackground: {
    '@supports (backdrop-filter: none) or (-webkit-backdrop-filter: none)': {
      '-webkit-backdrop-filter': 'saturate(120%) blur(16px)',
      backdropFilter: 'saturate(190%) blur(16px)',
      backgroundColor: 'rgba(16, 26, 38, 0.05)'
    }
  }
}))

function Analytics ({ hasGlass = false, className, ...rest }) {
  const classes = useStyles()
  const { baseDate, baseDashboardType, getBaseFilter } = baseValues
  const { user, updateAnalyticsFilters: ctxUpdateUserAnalyticsFilters } =
		useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const {
    inverters,
    orientations,
    energyMeters,
    sunMeters,
    uuid: plantId
  } = useSelector((state) => state.pvPlantView)
  const [filterOpen, setFilterOpen] = useState(false)
  const [dashboardType, setDashboardType] = useState(baseDashboardType)
  const [currentPeriod, setCurrentPeriod] = useState(baseDate)
  const [startDate, setStartDate] = useState(moment().subtract(1, 'days'))
  const [endDate, setEndDate] = useState(moment())
  const [currentFilter, setCurrentFilter] = useState(getBaseFilter())
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingStringAnalysisData, setIsLoadingStringAnalysisData] =
		useState(false)
  const [openPeriodModal, setOpenPeriodModal] = useState(false)

  // const isSmall = useMediaQuery(theme => theme.breakpoints.down('lg'))
  // Ref per calcolare l'altezza dei grafici
  const [rect, ref] = useClientRect()

  // Stato per i grafici
  const [regressionData, setRegressionData] = useState({
    regression: [],
    stringAnalysis: [],
    stringAnalysisPeriod: formatPeriod(moment().subtract(1, 'days'), moment())
  })
  // const [prTotalData, setPrTotalData] = useState({})
  const [stringAnalysisData, setStringAnalysisData] = useState([])
  const [trend, setTrend] = useState([])

  const getData = useCallback(
    async (type, timeFrom, timeTo, filter) => {
      const config = {}
      // Preparo l'oggetto config ad ospitare il numero corretto di grafici
      filter[type].forEach((el, index) => {
        // console.log(el)
        if (el.elements && el.elements.length > 0) {
          if (type === 'trend') {
            const properties = el.elements
            const elements = [
              ...new Set(properties.map((prop) => prop.deviceId))
            ].map((id) => ({
              properties: properties
                .filter((el) => el.deviceId === id)
                .filter((el) => el.selected === true)
                .map((el) => el.name),
              resourceType: properties.find((el) => el.deviceId === id)
                ? properties.find((el) => el.deviceId === id).deviceType
                : 'device',
              resourceId: id
            }))

            config[`graph${index + 1}`] = {
              aggregationType:
								el.aggregationType ||
								getAggregationTypeFromPeriod(timeFrom, timeTo),
              elements
              /* elements: el.elements.map(element => ({
              properties: (element.properties && element.properties.filter(el => el.selected === true).map(el => el.name)) || [],
              resourceType: element.deviceType && element.deviceType.category ? 'device' : 'orientation',
              resourceId: element.uuid
            })) */
            }
          } else {
            config[`graph${index + 1}`] = {
              aggregationType: getAggregationTypeFromPeriod(timeFrom, timeTo),
              elements: el.elements.map((element) => ({
                // Se ho scelto la configurazione dell'intero impianto
                resourceType:
									element.uuid === 'plant'
									  ? 'plant'
									  : element.deviceType && element.deviceType.category
									    ? element.deviceType.category.toLowerCase()
									    : 'orientation',
                // Se ho scelto la configurazione dell'intero impianto
                resourceId: element.uuid === 'plant' ? plantId : element.uuid
              }))
            }
          }
        }
      })

      log({ text: 'config => ', variable: config, tag: 'getDataconfig' })
      // Oggetto che contiene i parametri per effettuare la chiamata API
      const paramsObj = {
        type,
        timeFrom,
        timeTo,
        plantId,
        config
      }
      const params = JSON.stringify(paramsObj)
      // console.log(type, timeFrom, timeTo, filter)
      if (plantId) {
        const { data: response, error } = await api.get({
          savedUrl: 'plantDetails',
          path: `/${plantId}/analytics?q=${params}`
        })

        if (error) {
          return null
        }
        // const dataObj = response.data
        return response
      }
    },
    [plantId]
  )

  // Funzione che setta i dati dell'analisi di stringa in base al periodo selezionato nel grafico di regressione lineare
  const changeStringAnalysisPeriod = async (selectedISODate) => {
    setIsLoadingStringAnalysisData(true)
    if (!selectedISODate) {
      setIsLoading(true)
      try {
        const completeFilter = addStringAnalysisToDeviation(
          currentFilter,
          'deviation'
        )
        const deviationStringData = await getData(
          'stringAnalysis',
          startDate,
          endDate,
          completeFilter
        )
        // setto i dati per i grafici di analisi di stringa
        if (deviationStringData) {
          const newStringAnalysis =
						decodeStringAnalysisFromApi(deviationStringData)
          /* const newStringAnalysis = Object.keys(deviationStringData)
            .map(key => ({ ...deviationStringData[key] }))
            .map(element => Object.keys(element)
              .map(elKey => ({ ...element[elKey] }))).flat() */
          // setStringAnalysisData(newStringAnalysis)
          setRegressionData((prevRegressionData) => ({
            ...prevRegressionData,
            stringAnalysis: newStringAnalysis,
            stringAnalysisPeriod: moment(startDate).format('DD/MM/YYYY')
          }))
        }
      } catch (e) {
        return null
      }
      setIsLoading(false)
    } else {
      const { minDate, maxDate } = getDatesFromPeriod(
        'live',
        moment(selectedISODate)
      )
      try {
        const completeFilter = addStringAnalysisToDeviation(
          currentFilter,
          'deviation'
        )
        const deviationStringData = await getData(
          'stringAnalysis',
          minDate,
          maxDate,
          completeFilter
        )
        // setto i dati per i grafici di analisi di stringa
        if (deviationStringData) {
          const newStringAnalysis =
						decodeStringAnalysisFromApi(deviationStringData)
          /* const newStringAnalysis = Object.keys(deviationStringData)
            .map(key => ({ ...deviationStringData[key] }))
            .map(element => Object.keys(element)
              .map(elKey => ({ ...element[elKey] }))).flat() */
          // setStringAnalysisData(newStringAnalysis)
          setRegressionData((prevRegressionData) => ({
            ...prevRegressionData,
            stringAnalysis: newStringAnalysis,
            stringAnalysisPeriod: moment(minDate).format('DD/MM/YYYY')
          }))
        }
      } catch (e) {
        return null
      }
    }
    setIsLoadingStringAnalysisData(false)
  }

  useEffect(() => {
    async function initialiseView () {
      if (dashboardType === 'deviation') {
        const completeFilter = addStringAnalysisToDeviation(
          currentFilter,
          dashboardType
        )
        try {
          setIsLoading(true)
          setIsLoadingStringAnalysisData(true)
          const data = await getData(
            dashboardType,
            startDate,
            endDate,
            completeFilter
          )
          const deviationStringData = await getData(
            'stringAnalysis',
            startDate,
            endDate,
            completeFilter
          )
          // const data = await getData(dashboardType, startDate, endDate, currentFilter)
          // Se sto visualizzando una view di scostamento, devo aggiungere la chiamata di stringAnalysis
          // const deviationStringData = await getData('stringAnalysis', startDate, endDate, completeFilter)
          const {
            baselinePr,
            realPr,
            baselineProduction,
            realProduction,
            ...rest
          } = data
          log({ text: 'rest', variable: rest, tag: 'LinearRegressionChart' })
          // Preparo i dati per il grafico di regressione
          const linearRegressionData = decodeLinearRegressionGraphFromApi(rest)
          // Preparo i dati per il grafico di analisi di stringa
          const newStringAnalysis =
						decodeStringAnalysisFromApi(deviationStringData)
          // setto i dati per il grafico di regressione e per i grafici di analisi di stringa
          if (linearRegressionData && newStringAnalysis.length > 0) {
            setRegressionData({
              regression: linearRegressionData,
              stringAnalysis: newStringAnalysis,
              stringAnalysisPeriod: formatPeriod(
                moment(startDate),
                moment(endDate)
              )
            })
            // setRegressionData(linearRegressionData)
            // setStringAnalysisData(newStringAnalysis)
          }
          setIsLoading(false)
          setIsLoadingStringAnalysisData(false)
          // setto i dati per il grafico radial
          /* if (baselinePr && realPr && baselineProduction && realProduction) {
            setPrTotalData(decodeTotalPrGraphFromApi({
              baselinePr,
              realPr,
              baselineProduction,
              realProduction
            }))
          } */
        } catch (e) {
          return null
        }
      } else if (dashboardType === 'stringAnalysis') {
        setIsLoading(true)
        const data = await getData(
          dashboardType,
          startDate,
          endDate,
          currentFilter
        )
        if (data) {
          const newStringAnalysis = decodeStringAnalysisFromApi(data)
          setStringAnalysisData(newStringAnalysis)
        }
        setIsLoading(false)
      } else if (dashboardType === 'trend') {
        setIsLoading(true)
        const data = await getData(
          dashboardType,
          startDate,
          endDate,
          currentFilter
        )
        if (data) {
          const newTrend = Object.keys(data).map((key, index) =>
            decodeTrendGraph({ ...data[key] }, currentFilter.trend[index])
          )
          setTrend(newTrend)
        }
        setIsLoading(false)
      }
    }

    if (dashboardType && startDate && endDate && currentFilter) {
      initialiseView()
    }
  }, [dashboardType, startDate, endDate, currentFilter, getData])

  // Funzione che elimina un filtro utente
  const deleteFilter = async (filterId) => {
    const userFilters = user.metadata.analyticsFilters || []
    const newUserFilters = userFilters.filter((el) => el.uuid !== filterId)
    const newMetadata = {
      ...user.metadata,
      analyticsFilters: newUserFilters
    }
    const newUser = {
      ...user,
      metadata: newMetadata
    }
    const { data: responseUser, error } = await api.put({
      savedUrl: 'accounts',
      path: '/self',
      body: newUser
    })
    if (error) {
      return enqueueSnackbar("Errore durante l'eliminazione del filtro", {
        variant: 'error'
      })
    }
    const newFilters =
			responseUser.metadata && responseUser.metadata.analyticsFilters
			  ? responseUser.metadata.analyticsFilters
			  : null
    // 5. aggiornare il context se la chiamata va a buon fine
    if (newFilters) {
      ctxUpdateUserAnalyticsFilters(newFilters)
    }
    // 6. mostrare una notifica di successo/errore in base all'esito della chiamata
    enqueueSnackbar('Filtro eliminato con successo!', { variant: 'success' })
  }

  // Funzione che modifica e salva un filtro utente
  const editFilters = async (filter, name, type, startDate, endDate) => {
    const newFilter = {
      ...filter
    }
    const filterWithNormalizedElements =
			type === 'trend' ? newFilter : normalizeElementsFilter(newFilter, type)

    filterWithNormalizedElements.startDate = moment(startDate).toISOString()
    filterWithNormalizedElements.endDate = moment(endDate).toISOString()
    if (name) {
      filterWithNormalizedElements.name = name
    }

    // Filtri esistenti dell'utente, se non ce ne sono partiamo da un array vuoto
    const userFilters = user.metadata.analyticsFilters || []
    const currentFilterIndex = userFilters.findIndex(
      (el) => el.uuid === filterWithNormalizedElements.uuid
    )
    if (currentFilterIndex > -1) {
      const newUserFilters = [...userFilters]
      newUserFilters.splice(
        currentFilterIndex,
        1,
        filterWithNormalizedElements
      )
      // 2. creare un nuovo oggetto metadata con il filtro in questione
      const newMetadata = {
        ...user.metadata,
        analyticsFilters: newUserFilters
      }
      // 3. creare un nuovo oggetto utente aggiornato
      const newUser = {
        ...user,
        metadata: newMetadata
      }

      const { data: responseUser, error } = await api.put({
        savedUrl: 'accounts',
        path: '/self',
        body: newUser
      })
      if (error) {
        return enqueueSnackbar('Errore durante la modifica del filtro', {
          variant: 'error'
        })
      }
      const newFilters =
				responseUser.metadata && responseUser.metadata.analyticsFilters
				  ? responseUser.metadata.analyticsFilters
				  : null
      // 5. aggiornare il context se la chiamata va a buon fine
      if (newFilters) {
        ctxUpdateUserAnalyticsFilters(newFilters)
      }
      // 6. mostrare una notifica di successo/errore in base all'esito della chiamata
      enqueueSnackbar('Filtro modificato con successo!', {
        variant: 'success'
      })
    }
  }

  // Funzione che crea e salva un filtro utente
  const saveFilters = async (filter, name, type, startDate, endDate) => {
    let filterCopy = { ...filter }
    // In caso sto salvando un filtro di tipo deviation, devo aggiungere anche il filtro stringAnalysis per via della doppia chiamata
    if (type === 'deviation') {
      filterCopy = addStringAnalysisToDeviation(filter, type)
    }
    // 1. Creare un uuid per il filtro
    const filterId = uuidv4()
    // Rimuovo le informazioni non utilizzate dai devices all'interno dei filtri
    const filterWithNormalizedElements =
			type === 'trend' ? filterCopy : normalizeElementsFilter(filterCopy, type)
    const newFilter = {
      ...filterWithNormalizedElements,
      name,
      type,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      plantId,
      uuid: filterId
    }
    // Filtri esistenti dell'utente, se non ce ne sono partiamo da un array vuoto
    const userFilters = user.metadata.analyticsFilters || []
    const newUserFilters = [...userFilters, newFilter]
    // 2. creare un nuovo oggetto metadata con il filtro in questione
    const newMetadata = {
      ...user.metadata,
      analyticsFilters: newUserFilters
    }
    // 3. creare un nuovo oggetto utente aggiornato
    const newUser = {
      ...user,
      metadata: newMetadata
    }
    // 4. eseguire la chiamata api che aggiorna l'utente
    const { data: responseUser, error } = await api.put({
      savedUrl: 'accounts',
      path: '/self',
      body: newUser
    })
    if (error) {
      return enqueueSnackbar('Errore durante la creazione del filtro', {
        variant: 'error'
      })
    }
    const newFilters =
			responseUser.metadata && responseUser.metadata.analyticsFilters
			  ? responseUser.metadata.analyticsFilters
			  : null
    // 5. aggiornare il context se la chiamata va a buon fine
    if (newFilters) {
      ctxUpdateUserAnalyticsFilters(newFilters)
    }
    // 6. mostrare una notifica di successo/errore in base all'esito della chiamata
    enqueueSnackbar('Filtro creato con successo!', { variant: 'success' })
  }

  // Funzione che prende in ingresso il filtro corrente e crea la stringa contenente gli elementi presi in considerazione dal filtro
  const getElements = (filter) => {
    const currentFilterView = filter[dashboardType]
    if (currentFilterView) {
      // Ricreo un array contenente tutti gli elementi di ogni grafico dei filtri
      const currentElements = currentFilterView.flatMap(
        (currFilter) => currFilter.elements
      )
      const uniqueElements = [
        ...new Set(
          currentElements
            .filter((el) => el && el !== undefined)
            .map((el) => el.uuid)
        )
      ].map((uuid) => currentElements.find((el) => el.uuid === uuid))

      return dashboardType === 'trend'
        ? currentElements.map((el) => el.displayName || '').join(', ')
        : uniqueElements.map((el) => el.name || '').join(', ')
    }
  }

  const graphHeight = rect ? rect.height - 220 : '100%'

  // funzione che in base al tipo di dashboard da visualizzare restituisce i grafici corretti
  const getCurrentDashboard = (viewType) => {
    switch (viewType) {
      case 'deviation': {
        return (
          <Box height='94%'>
            <Grid
              container
              spacing={2}
              style={{ height: '98%', overflowY: 'auto' }}
            >
              <Grid item xs={6} style={{ height: '98%', overflowY: 'auto' }}>
                {!isLoading ? (
                  <Card
                    elevation={hasGlass ? 0 : 1}
                    className={
											hasGlass
											  ? clsx(classes.section, classes.glassBackground)
											  : classes.section
										}
                  >
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <Typography className={classes.sectionTitle} variant='h6'>
                        Regressioni lineari
                      </Typography>
                      <Tooltip title='Resetta periodo grafico'>
                        <IconButton
                          onClick={async () => changeStringAnalysisPeriod(null)}
                          size='small'
                        >
                          <HomeIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box
                          mt={1}
                          width='100%'
                          height='100%'
                          display='flex'
                          alignItems='center'
                          justifyContent='center'
                        >
                          <LinearRegressionChart
                            height={graphHeight < 300 ? 300 : graphHeight}
                            onDataPointClick={changeStringAnalysisPeriod}
                            data={regressionData.regression}
                          />
                        </Box>
                      </Grid>
                      {/* <Grid item xs={12} md={4} xl={3}>
                      <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
                        <TotalPrGraph data={prTotalData} />
                      </Box>
                    </Grid> */}
                    </Grid>
                  </Card>
                ) : (
                  <Box
                    width='100%'
                    height='100%'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <LoadingCard
                      glass={hasGlass}
                      className={classes.loading}
                      size={40}
                    />
                  </Box>
                )}
              </Grid>
              <Grid
                item
                xs={6}
                style={{
								  height: '98%',
								  overflowY:
										regressionData.stringAnalysis?.length > 1
										  ? 'auto'
										  : 'hidden'
                }}
              >
                {!isLoadingStringAnalysisData &&
								regressionData.stringAnalysis?.length > 0
                  ? (
								      regressionData.stringAnalysis.map((el, elIndex) => (
  <Card
    elevation={hasGlass ? 0 : 1}
    className={
												hasGlass
												  ? clsx(classes.section, classes.glassBackground)
												  : classes.section
											}
    key={`deviation-stringAnalysis-${elIndex}`}
    style={{ paddingBottom: '48px', marginBottom: '8px' }}
  >
    <Grid container spacing={2}>
      {el.map((singleGraph, singleGraphIndex) => (
        <Grid
          key={`deviation-stringAnalysisGraph-${elIndex}-${singleGraphIndex}`}
          item
          xs={12}
        >
          <Typography
            variant='h6'
            className={classes.sectionTitle}
          >
            {singleGraph &&
															singleGraph.orientation &&
															singleGraph.orientation.name
															  ? `Analisi di stringa: ${singleGraph.orientation.name} - ${regressionData.stringAnalysisPeriod}`
															  : ''}
          </Typography>
          <Box
            width='100%'
            height='100%'
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <StringAnalysisChart
              name={`string-analysis-${elIndex}-${singleGraphIndex}`}
              height={
																	graphHeight < 280
																	  ? 280
																	  : el.length > 1
																	    ? graphHeight * 0.5
																	    : graphHeight
																}
              data={singleGraph}
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  </Card>
								      ))
								    )
                  : (
                    <Box
                      width='100%'
                      height='100%'
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                    >
                      <LoadingCard
                        glass={hasGlass}
                        className={classes.loading}
                        size={40}
                      />
                    </Box>
								    )}
              </Grid>
            </Grid>
          </Box>
        )
      }
      case 'stringAnalysis': {
        return (
          <Box>
            <Grid container spacing={2}>
              {!isLoading &&
							stringAnalysisData &&
							stringAnalysisData.length > 0 ? (
							      stringAnalysisData.map((el, elIndex) => (
  <Grid key={`stringAnalysis-${elIndex}`} item xs={12}>
    <Card
      elevation={hasGlass ? 0 : 1}
      className={
												hasGlass
												  ? clsx(classes.section, classes.glassBackground)
												  : classes.section
											}
    >
      <Grid container spacing={2}>
        {el.map((singleGraph, singleGraphIndex) => (
          <Grid
            key={`stringAnalysisGraph-${elIndex}-${singleGraphIndex}`}
            item
            xs={el.length > 1 ? 6 : 12}
          >
            <Typography
              variant='h6'
              className={classes.sectionTitle}
            >
              {singleGraph &&
															singleGraph.orientation &&
															singleGraph.orientation.name
															  ? `Analisi di stringa: ${singleGraph.orientation.name}`
															  : ''}
            </Typography>
            <Box
              width='100%'
              height='100%'
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <StringAnalysisChart
                name={`string-analysis-${elIndex}-${singleGraphIndex}`}
                data={singleGraph}
                height={
																	// l'altezza del grafico non deve essere minore di 280
																	graphHeight < 280
																	  ? 280
																	  : // se ci sono 1 o 2 grafici in totale, l'altezza deve essere al massimo della pagina
																	  stringAnalysisData.length < 2
																	    ? graphHeight
																	    : graphHeight * 0.5
																}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Card>
  </Grid>
							      ))
							    ) : (
  <Box
    width='100%'
    height='100%'
    display='flex'
    alignItems='center'
    justifyContent='center'
  >
    <LoadingCard
      glass={hasGlass}
      className={classes.loading}
      size={40}
    />
  </Box>
							    )}
            </Grid>
          </Box>
        )
      }
      case 'trend': {
        return (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card
                  elevation={hasGlass ? 0 : 1}
                  className={
										hasGlass
										  ? clsx(classes.section, classes.glassBackground)
										  : classes.section
									}
                >
                  <Grid container spacing={2}>
                    {!isLoading && trend.length > 0
                      ? (
										  trend.map((el, elIndex) =>
										    currentFilter[dashboardType][elIndex].show === true
                              ? (
                                <Grid item xs={12} key={`trend-${elIndex}`}>
                                  <Typography
                                    className={classes.sectionTitle}
                                    variant='h6'
                                  >
                                    {`Andamento nel tempo ${elIndex + 1}`}
                                  </Typography>
                                  <Box
                                    width='100%'
                                    height='100%'
                                    display='flex'
                                    alignItems='center'
                                    justifyContent='center'
                                  >
                                    <TrendOverTimeGraph
                                      name={`trend-${elIndex}`}
                                      height={
																	graphHeight < 300
																	  ? 300
																	  : trend.length > 1
																	    ? graphHeight * 0.5
																	    : graphHeight
																}
                                      data={el}
                                    />
                                  </Box>
                                </Grid>
										    )
                              : (
                                <Grid item xs={12}>
                                  <Typography
                                    className={classes.sectionTitle}
                                    variant='h6'
                                  >
                                    {`Andamento nel tempo ${elIndex + 1}`}
                                  </Typography>
                                  <Typography variant='body1'>
                                    Grafico non attivo
                                  </Typography>
                                </Grid>
										    )
										  )
                        )
                      : (
                        <Box
                          width='100%'
                          height='100%'
                          display='flex'
                          alignItems='center'
                          justifyContent='center'
                        >
                          <LoadingCard
                            glass={hasGlass}
                            className={classes.loading}
                            size={40}
                          />
                        </Box>
                        )}
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Box>
        )
      }
      default: {
        return null
      }
    }
  }

  // funzione che in base al parametro ricevuto, porta data iniziale e data finale di un periodo indietro o avanti
  const nextOrPrevPeriod = (type, start, end) => {
    const newStartDate =
			type === 'prev'
			  ? moment(start).subtract(1, 'days')
			  : moment(start).add(1, 'days')
    const newEndDate =
			type === 'prev'
			  ? moment(end).subtract(1, 'days')
			  : moment(end).add(1, 'days')
    const newPeriod = `${newStartDate.format('DD/MM')} - ${newEndDate.format('DD/MM')}`
    setStartDate(newStartDate)
    setEndDate(newEndDate)
    setCurrentPeriod(newPeriod)
  }

  // console.log('regressionData: ', regressionData)
  // console.log('stringAnalysisData: ', stringAnalysisData)

  return (
  // <div className={isSmall ? clsx(classes.root, className, classes.smallViewport) : clsx(classes.root, className)} {...rest}>
    <div className={clsx(classes.root, className)} {...rest}>
      {openPeriodModal ? (
        <PeriodModal
          confirm={(start, end) => {
					  // console.log(start, end)
					  const newPeriod = `${moment(start).format('DD/MM')} - ${moment(end).format('DD/MM')}`
            setStartDate(start)
            setEndDate(end)
            setCurrentPeriod(newPeriod)
            setOpenPeriodModal(false)
          }}
          start={startDate}
          end={endDate}
          open={openPeriodModal}
          onClose={() => setOpenPeriodModal(false)}
        />
      ) : null}
      <Box mt={2}>
        <Box width='100%' display='flex' alignItems='center'>
          <Typography className={classes.filterTitle} variant='h6'>
            Filtri attivi
          </Typography>
        </Box>
        <Grid width='100%' container spacing={2}>
          <Grid item xs={10} md={10} xl={8}>
            <Box width='100%' display='flex' alignItems='center'>
              {getDashboardTypes().find((el) => el.value === dashboardType)
                ? (
                  <Chip
                    size='small'
                    className={classes.chip}
                    label={`Tipo: ${getDashboardTypes().find((el) => el.value === dashboardType).label}`}
                  />
                  )
                : null}
              {currentPeriod ? (
                <Chip
                  size='small'
                  className={classes.chip}
                  label={currentPeriod}
                  color='secondary'
                  onClick={() => setOpenPeriodModal(true)}
                  deleteIcon={
										moment().diff(endDate, 'days') > 0
										  ? (
  <ChevronRight
    style={{ cursor: 'pointer' }}
    size={18}
    color='#e0e0e0'
  />
										    )
										  : null
									}
                  {...{
									  onDelete:
											moment().diff(endDate, 'days') > 0
											  ? () => nextOrPrevPeriod('next', startDate, endDate)
											  : null
                  }}
                  icon={
                    <ChevronLeft
                      size={18}
                      onClick={(e) => {
											  // serve stoppare la propagazione dell'evento per impedire
											  // anche l'apertura del calendario invece che la chiamata alla funzione di cambio periodo
											  e.stopPropagation()
                        nextOrPrevPeriod('prev', startDate, endDate)
                      }}
                    />
									}
                />
              ) : null}
              {currentFilter && Object.keys(currentFilter).length > 0
                ? (
                  <Tooltip title={getElements(currentFilter)}>
                    <Chip
                      style={{ maxWidth: 300 }}
                      size='small'
                      className={classes.chip}
                      label={getElements(currentFilter)}
                    />
                  </Tooltip>
                  )
                : null}
            </Box>
          </Grid>
          <Grid item xs={2} xl={4}>
            <Box
              width='100%'
              display='flex'
              alignItems='center'
              justifyContent='flex-end'
            >
              <Button
                onClick={() => setFilterOpen(true)}
                size='small'
                variant='contained'
                color='primary'
                startIcon={<FilterIcon size={14} />}
              >
                Modifica
              </Button>
            </Box>
            <SidebarFilters
              applyFilters={(filter, viewType, startPeriod, endPeriod) => {
							  setCurrentFilter(filter)
                setDashboardType(viewType)
                setStartDate(startPeriod)
                setEndDate(endPeriod)
                setCurrentPeriod(
							    formatPeriod(moment(startPeriod), moment(endPeriod))
							  )
              }}
              plantId={plantId}
              editFilters={editFilters}
              saveFilters={saveFilters}
              deleteFilter={deleteFilter}
              orientations={orientations}
              inverters={inverters}
              sunMeters={sunMeters}
              energyMeters={energyMeters}
              open={filterOpen}
              onClose={() => setFilterOpen(false)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        ref={ref}
        width='100%'
        height='calc(100% - 80px)'
        style={{ overflowX: 'hidden', overflowY: 'auto' }}
      >
        {getCurrentDashboard(dashboardType)}
      </Box>
    </div>
  )
}
export default Analytics
